@use '/src/styles/variables.scss';

.drawer {
    .drawerHeader {
        width: variables.$drawerWidth;
    }
}

.drawerOpen {
    margin-left: variables.$drawerWidth;
}
